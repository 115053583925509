<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td>
                                开票编号：
                            </td>
                            <td style="width: 28%">
                                <el-input v-model="searchItem.applyNum" size="small"
                                          placeholder="请输入开票编号或合同号"></el-input>
                            </td>
                            <td>
                                销售人员：
                            </td>
                            <td style="width: 28%">
                                <el-select v-model="searchItem.userName" size="small"
                                           style="width: 100%"
                                           filterable
                                           placeholder="请选择销售人员">
                                    <el-option
                                            v-for="item in saleList"
                                            :key="item"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </td>
                            <td>
                                联系电话：
                            </td>
                            <td style="width: 28%">
                                <el-input v-model.number="searchItem.userTel" size="small" placeholder="请输入联系电话"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td style=" padding-top: 10px">
                                审批时间：
                            </td>
                            <td style="padding-top: 10px;width: 28%">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 46%;"
                                        value-format="yyyy-MM-dd"
                                        default-time='00:00:00'>
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date"
                                        placeholder="选择日期时间"
                                        size="small"
                                        style="width: 46%;"
                                        value-format="yyyy-MM-dd"
                                        default-time='23:59:59'>
                                </el-date-picker>
                            </td>
                            <td style="padding-top: 10px">
                                公司名称：
                            </td>
                            <td style="padding-top: 10px;width: 28%">
                                <el-input v-model="searchItem.companyName" size="small" placeholder="请输入公司名称"
                                ></el-input>
                            </td>
                            <td style="padding-top: 10px">
                                开票类型：
                            </td>
                            <td>
                                <el-select v-model="searchItem.invoiceType"
                                           style="padding-top: 10px;width: 100%" size="small"
                                           placeholder="请选择开票类型">
                                    <el-option
                                            v-for="item in billTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colspan="2" style="text-align: center;padding-top: 10px">
                                <!--<el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel">导出为Excel文件</el-button>-->
                                <el-button type="primary" icon="el-icon-search" size="small"
                                           @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" style="margin-left: 25%" icon="el-icon-refresh-right"
                                           size="small" @click="resetBtn">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                            <el-table-column prop="applyNum" label="申请编号" width="180" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <!-- <el-table-column prop="BillType" label="开票类型" width="120" align="center"></el-table-column>-->
                            <el-table-column prop="userName" label="销售人员" width="80" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="userTel" label="联系电话" width="110" align="center"></el-table-column>
							<el-table-column prop="contractNum" label="合同编号" width="180" align="center"></el-table-column>
                            <el-table-column label="开票金额(元)" width="110" align="right">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.invoiceAmount == 0">0</span>
                                    <span v-else><span v-if="scope.row.invoiceAmount != 0">{{(scope.row.invoiceAmount/100).toFixed(2)}}</span></span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="companyName" label="公司名称" width="280" show-overflow-tooltip
                                             align="center"></el-table-column>
                            <el-table-column label="开票类型" width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.invoiceType == 0">普通发票</span>
                                    <span v-if="scope.row.invoiceType == 1">专用发票</span>
                                    <span v-if="scope.row.invoiceType == 2">电子发票</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" :formatter="formartDate" label="审批时间" width="180"
                                             align="center"></el-table-column>
                            <el-table-column prop="createUserName" label="审批人" width="90"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column label="审批状态" width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.isPass == 0" style="color: red">已驳回</span>
                                    <span v-else style="color: green">已通过</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="remark" label="开票备注" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.remark == null || scope.row.remark == ''">无</span>
                                    <span v-else>{{scope.row.remark}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="auditRemark" label="审批意见" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" label="操作" width="50" align="center">
                                <template slot-scope="scope">
                                    <i class="iconfont iconxiangqing" title="详情" @click="showBill(scope.row.id)"></i>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="export">
            <el-dialog
                    title="开票申请"
                    :visible.sync="exportDialogVisible"
                    :close-on-click-modal="isClose"
                    width="52.6%" center>
                <download-excel
                        class="export-excel-wrapper"
                        :data="json_data"
                        :fields="json_fields"
                        :name="fileName">
                    <el-table
                            :data="exportData"
                            max-height="400px"
                            size="small"
                            row-class-name="row"
                            cell-class-name="column"
                            :highlight-current-row="true"
                            fit>
                        <el-table-column
                                v-for="(item, index) in jsonFields"
                                :key="index"
                                :prop="item.prop"
                                :width="item.width"
                                :label="item.label" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                    <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
                    <el-button type="primary" size="small" style="margin-left: 48%;margin-top: 1%"
                               @click="exportDialogVisible=false">导出数据
                    </el-button>
                </download-excel>
            </el-dialog>
        </div>
        <div class="information">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoVisible"
                    :close-on-click-modal="false"
                    width="50%"
                    center>
                <div class="dialog-body">
                    <div class="basic-info">
                        <div class="text-title">
                            基础信息
                        </div>
                        <div>
                            <table style="text-align: left;width: 100%">
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        申请编号：
                                    </td>
                                    <td style="width: 300px;">
                                        {{billForm.applyNum}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">合同编号：</td>
                                    <td style="width: 170px">
                                        {{billForm.contractNum}}
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        纳税人识别号：
                                    </td>
                                    <td style="width: 300px;">
                                        {{billForm.taxpayersNum}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">开票类型：</td>
                                    <td style="width: 170px">
                                        {{billForm.invoiceType==0?'普通发票': (billForm.invoiceType==1?'专用发票':'电子发票')}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">公司名称：</td>
                                    <td style="width: 300px">
                                        {{billForm.companyName}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">开票金额(元)：</td>
                                    <td style="width: 170px">
                                        {{billForm.invoiceAmount == 0?0:(billForm.invoiceAmount/100).toFixed(2)}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        地址：
                                    </td>
                                    <td style="width: 300px;" colspan="3">
                                        {{billForm.companyAddress}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">开户行：</td>
                                    <td style="width: 170px">
                                        {{billForm.companyBank}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">
                                        银行账号：
                                    </td>
                                    <td style="width: 300px;" colspan="5">
                                        {{billForm.companyAccount}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">塔机数量(台)：</td>
                                    <td style="width: 300px">
                                        {{billForm.towerCount}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">塔机服务月数(台/月)：</td>
                                    <td style="width: 170px">
                                        {{billForm.towerServiceMonths}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 140px">升降机数量(台)：</td>
                                    <td style="width: 300px">
                                        {{billForm.lifterCount}}
                                    </td>
                                    <td style="padding: 10px;width: 140px">升降机服务月数(台/月)：</td>
                                    <td style="width: 170px">
                                        {{billForm.lifterServiceMonths}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        备注：
                                    </td>
                                    <td style="width: 300px;" colspan="5">
                                        {{billForm.remark==''?'无':billForm.remark}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import bill from '../../../public/json/bill.json'
    import funOptions from '../../../public/json/funOptions.json'
    import basicData from '../../../public/json/basicData.json'

    export default {
        data() {
            return {
                pageList: [14, 20, 30, 50],
                tableHeight: '',
                jsonFields: [
                    {label: '申请编号', width: '120', prop: 'applyNum'},
                    {label: '合同编号', width: '120', prop: 'contractNum'},
                    {label: '销售人员', width: '120', prop: 'salesman'},
                    {label: '开票金额(元)', width: '120', prop: 'invoiceAmount'},
                    {label: '公司名称', width: '120', prop: 'companyName'},
                    {label: '开票类型', width: '120', prop: 'invoiceType'},
                    {label: '开户行', width: '120', prop: 'companyBank'},
                    {label: '开户账号', width: '120', prop: 'companyAccount'},
                    {label: '审批时间', width: '120', prop: 'approvalStatus'},
                    {label: '备注', width: '120', prop: 'remark'},
                ],
                fileName: '',
                fields: [],
                values: [],
                json_fields: {},
                json_data: [],
                json_meta: [
                    [
                        {
                            " key ": " charset ",
                            " value ": " utf- 8 "
                        }
                    ]
                ],
                exportData: [],
                exportDialogVisible: false,
                title: '',
                lineHeight: '',
                basicTable: [],
                checkAll: false,
                functions: [],
                funNameList: [],
                isIndeterminate: false,
                dialogVisibles: false,
                shippingVisible: false,
                infoVisible: false,
                multipleSelection: [],
                delList: [],
                saleList: [],
                // totalPage:0,
                searchItem: {
                    applyNum: '',
                    invoiceType: null,
                    companyName: '',
                    pageSize: 14,
                    startDate: '',
                    endDate: '',
                    userName: '',
                    userTel: '',
                },
                totalPage: 30,
                dialogVisible: false,
                option: {
                    pageIndex: '',
                    pageSize: 14,
                    currentPage: 2
                },
                showTitle: '',
                billTypes: [
                    {
                        label: '普通发票',
                        value: 0
                    },
                    {
                        label: '专用发票',
                        value: 1
                    },
                    {
                        label: '电子发票',
                        value: 2
                    }
                ],
                UpDialogVisible: false,
                tableData: [],
                billForm: {
                    contractNum: '',
                    applyNum: '',
                    companyName: '',
                    invoiceType: '',
                    invoiceAmount: '',
                    companyAddress: '',
                    companyBank: '',
                    taxpayersNum: '',
                    companyAccount: '',
                    auditRemark: '',
                    lifterCount: '',
                    lifterServiceMonths: '',
                    towerCount: '',
                    towerServiceMonths: '',
                    remark: '',
                    id: ''
                },
                rules: {
                    name: [{required: true, message: '开票名称不能为空', trigger: 'blur'}],
                    number: [{required: true, message: '开票号不能为空', trigger: 'blur'}],
                    quality: [{required: true, message: '数量不能为空', trigger: 'blur'}]
                },
                type: '',
                isClose: false
            }
        },
        methods: {
            pageChange(option) {
                this.option.pageIndex = option;
                this.searchItem.pageIndex = option;
                this.$api.pageAuditInvoice(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pageAuditInvoice(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            chooseSelection(val) {
                this.multipleSelection = val;
            },
            resetBtn() {
                this.searchItem = {
                    applyNum: '',
                    invoiceType: null,
                    companyName: '',
                    pageSize: 14,
                    startDate: '',
                    endDate: '',
                    salesman: '',
                    phone: '',
                };
                this.pageChange(1);
            },
            passBill(id) {
                this.$api.approveApplyInvoice({id: id, isPass: 'Y'}).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.message);
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            formartDate(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            exportExcel() {
                this.exportData = [];
                this.searchItem.pageSize = 1000;
                this.exportDialogVisible = true;
                this.$api.pageApplyInvoice(this.searchItem).then(res => {
                    for (var i = 0; i < res.data.content.length; i++) {
                        res.data.content[i].invoiceAmount = res.data.content[i].invoiceAmount / 100;
                        this.exportData.push(res.data.content[i]);
                    }
                })
            },
            searchBtn() {
                this.pageChange(1);
            },

            showBill(id) {
                this.infoVisible = true;
                this.$api.getAuditInvoiceById({id}).then(res => {
                    if (res.code == 200) {
                        this.billForm = {
                            contractNum: res.data.contractNum,
                            applyNum: res.data.applyNum,
                            taxpayersNum: res.data.taxpayersNum,
                            companyName: res.data.companyName,
                            invoiceType: res.data.invoiceType,
                            invoiceAmount: res.data.invoiceAmount,
                            companyAddress: res.data.companyAddress,
                            companyBank: res.data.companyBank,
                            companyAccount: res.data.companyAccount,
                            lifterCount: res.data.lifterCount,
                            lifterServiceMonths: res.data.lifterServiceMonths,
                            towerCount: res.data.towerCount,
                            towerServiceMonths: res.data.towerServiceMonths,
                            auditRemark: res.data.auditRemark,
                            remark: res.data.remark,
                            id: res.data.id
                        }
                        this.showTitle = "《" + res.data.applyNum + "》" + '开票申请详情'
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },

            handleCheckAllChange(val) {
                this.billForm.demand = val ? this.functions : [];
                this.isIndeterminate = false;
            },
            handleCheckedFunctionsChange(value) {
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.functions.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.functions.length;
            }
        },
        created() {
            this.searchBtn();
            this.$api.getSaleList().then(res => {
                if (res.code == 200){
                    this.saleList = res.data
                }
            })
            this.tableHeight = (window.innerHeight) * 0.67
            this.functions = funOptions.data;
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    }
</script>

<style lang="scss" scoped>

    .approve {
        display: flex;
    }

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .page-body {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 130px;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
        margin-left: 10px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .information {
        background-color: #F0F2F5;
    }

    .dialog-title {
        margin: 0 20px;
        color: #67ADD9;
        font-size: 20px;
        /*border: 1px red solid;*/
    }

    .payment-table, .approval-table {
        margin-top: 10px;
    }


    .text-title {
        font-size: 16px;
        font-weight: bold;
        color: #437EE6;
        text-align: center;
        margin-bottom: 20px;
    }

    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }
</style>
